import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import UserContext from './appContext';

const ProtectedRoute = ({ children }) => {
    const { userToken, showLogin } = useContext(UserContext);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() =>{
        if (showLogin && !userToken) {
            navigate("/login", {search:location.search, from: location});
        }
    },[userToken, showLogin]);

    if (!userToken) {
        return <></>;
    }
  
    return children;
};

  export default ProtectedRoute;