import React from "react";
import { useTranslation } from "react-i18next";
const Terms = () => {
    const { i18n,t } = useTranslation();
    return (
        <div className="container-page">
            <div className="content-page">
                <h5>{t("TERMS")}</h5>
            </div>
        </div>
    );
};
  
export default Terms;