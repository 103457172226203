import React, {useContext} from "react";
import { NavLink } from "react-router-dom";
import UserContext from "../../generic/components/appContext";

const Help = () => {
    const { tutorialVideo } = useContext(UserContext);
    
    return(

        <div className="container-page">
            <div className="content-page ps-3 pe-3 pb-3">
                <div className="mytournamentsHeader">Tutorial</div>
                {tutorialVideo !== "" &&
                    <div className="contentDisplay pb-2">
                        <video id="tutorialsPlayer" playsInline="" webkit-playsinline="" poster=""  muted="" preload="none" controls src={tutorialVideo}>
                            <source src="" type="video/webm"/>
                            <source src="" type="video/mp4"/>
                        </video>
                    </div>
                }               
                <h5 className="TutorialHeader">Συχνές Ερωτήσεις</h5>
                <div className="page-header">Πώς παίζω;</div>
                <div className="page-desc">Κάθε τουρνουά έχει συγκεκριμένο αριθμό μαχών (αναγράφεται.) μεταξύ τριών παικτών που επιλέγονται τυχαία.<br/>Σκοπός είναι η συλλογή πόντων έτσι ώστε να βρεθείτε ανάμεσα στις θέσεις επάθλων του βαθμολογικού πίνακα. <br/><br/>Ξεκινάτε το παιχνίδι περιστρέφοντας τους κυλίνδρους για να συλλέγετε πόντους. Κατά τη διάρκεια της μάχης, ένα «Σεντούκι» μπορεί να προσγειωθεί στον κύλινδρο 3. Αυτομάτως αυτό θα ανοίξει, ξεκλειδώνοντας τα διαθέσιμα όπλα που έχετε κατά των αντιπάλων σας. Κάθε φορά που ανοίγει έχετε 10 δευτερόλεπτα και μία από τις 3 εναλλακτικές:<br/><br/>
                -	Να επιλέξετε όπλο και αντίπαλο για επίθεση.<br/>
                -	Να επιλέξετε αμυντικό όπλο όπως την «Ασπίδα» για να υπερασπιστείτε το σκορ σας. <br/>
                -	Να συνεχίσετε το παιχνίδι χωρίς να επιλέξετε όπλο.<br/><br/>
                Τα διαθέσιμα όπλα και ο αριθμός που μπορούν να χρησιμοποιηθούν διαφέρουν ανάλογα με το τουρνουά. Υπάρχουν «αμυντικά» όπλα που σας επιτρέπουν να προστατέψετε το σκορ σας και «επιθετικά» όπλα για να επιτεθείτε στο σκορ των αντιπάλων σας. Όλες οι πληροφορίες για τα όπλα αναγράφονται στο λόμπι ή στο παιχνίδι κατά τη διάρκεια ανοίγματος του Σεντουκιού.
                </div>

                
                <div className="page-header">Πόσο διαρκεί ένα παιχνίδι;</div>
                <div className="page-desc">Ο χρόνος παιχνιδιού εξαρτάται από τον τύπο του τουρνουά, ωστόσο τα παιχνίδια συνήθως διαρκούν μεταξύ 100 και 300 δευτερολέπτων.</div>
                <div className="page-header">Τι είναι το κάθε σύμβολο;</div>
                <div className="page-desc">Κάθε σύμβολο αντιπροσωπεύει μια συγκεκριμένη μη-χρηματική αξία. Όσο υψηλότερη η αξία των συμβόλων, τόσο περισσότερους πόντους θα κερδίσετε από νικητήριο συνδυασμό σε μια περιστροφή.</div>
                <div className="page-header">Τι είναι ο αριθμός δίπλα στα σύμβολα όπλων;</div>
                <div className="page-desc">Ο αριθμός των χρήσεων που απομένουν σε ένα όπλο. Κάθε όπλο έχει έναν συγκεκριμένο αριθμό χρήσεων που μπορείτε να χρησιμοποιήσετε κατά τη διάρκεια μιας μάχης.</div>
                <div className="page-header">Πώς μπορώ να χρησιμοποιήσω τα όπλα;</div>
                <div className="page-desc">Μπορείτε να χρησιμοποιήσετε τα όπλα όταν προσγειωθεί το Σεντούκι στον τρίτο κύλινδρο. Έχετε 10 δευτερόλεπτα να επιλέξετε όπλο. Προσοχή, αν πατήσετε το κουμπί της περιστροφής όταν εμφανιστεί το Σεντούκι, συνεχίζετε τη μάχη χωρίς να επιλέξετε όπλο.</div>
                <div className="page-header">Γιατί δεν λειτούργησε το όπλο μου;</div>
                <div className="page-desc">YΓια να χρησιμοποιήσετε ένα όπλο πρέπει πρώτα να το επιλέξετε. Αν επιλέξετε όπλο επίθεσης, αμέσως μετά πρέπει να επιλέξετε σε ποιον αντίπαλο θα το χρησιμοποιήσετε. Βεβαιωθείτε ότι έχετε κάνει την επιλογή σας εντός 10 δευτερολέπτων ειδάλλως το όπλο δεν θα δουλέψει.</div>
                <div className="page-header">Γιατί έχασα τους βαθμούς μου;</div>
                <div className="page-desc">Οι πόντοι κερδίζονται και χάνονται κατά τη διάρκεια ενός παιχνιδιού είτε από επιθέσεις είτε άμυνες μεταξύ των παικτών. Οι πόντοι δεν έχουν χρηματική αξία. Χρησιμοποιούνται μόνο για να ανεβείτε στον βαθμολογικό πίνακα (leaderboard) στις θέσεις που κερδίζουν έπαθλα.</div>
                <div className="page-header">Η συμμετοχή στο τουρνουά έχει κάποιο κόστος;</div>
                <div className="page-desc">Όχι, τα τουρνουά μας είναι προς το παρόν δωρεάν.</div>
                <div className="page-header">Πώς μπορώ να κερδίσω;?</div>
                <div className="page-desc">Μονομαχείτε στα τουρνουά για να συλλέξετε πόντους. Αν το άθροισμα των πόντων σας σάς κατατάσσει σε θέση νίκης όταν ολοκληρωθεί το τουρνουά που συμμετείχατε, τότε κερδίζετε έπαθλο.</div>
                <div className="page-header">Τι μπορώ να κερδίσω;</div>
                <div className="page-desc">Τα έπαθλα σε κάθε τουρνουά καθορίζονται και θα αναγράφονται ευδιακρίτως. Αυτά μπορεί να είναι Free Spins, bonus και φυσικά έπαθλα.</div>
                <div className="page-header">Πώς λαμβάνω το έπαθλό μου;</div>
                <div className="page-desc">Το κάθε έπαθλο πιστώνεται αυτόματα στον λογαριασμό σας στη bwin εντός  24 ωρών.</div>
                <div className="page-header">Γιατί δεν έχω λάβει το έπαθλό μου;</div>
                <div className="page-desc">Εάν είστε ανάμεσα στους νικητές, το έπαθλό θα πιστωθεί σύντομα στον λογαριασμό σας. Εάν δεν πιστωθεί παρακαλούμε επικοινωνήστε με το τμήμα υποστήριξής μας.</div>
                <div className="page-header">Ποιο είναι το όνομα χρήστη μου;</div>
                <div className="page-desc">Με την πρώτη είσοδό σας στο bwin Arena, επιλέγετε Username/Όνομα Χρήστη. Προσοχή, είναι οριστικό και δεν μπορεί να αλλάξει.</div>
                <div className="page-header">Γιατί σταμάτησε το παιχνίδι;</div>
                <div className="page-desc">Σε περίπτωση διακοπής του παιχνιδιού, δοκιμάστε να ξαναφορτώσετε τη σελίδα. Συνήθως αυτό θα λύσει το πρόβλημα που μπορεί να οφείλεται σε σφάλμα δικτύου.</div>
                
    
            </div>
        </div>
        
    );
};
  
export default Help;