import React,{ useEffect, useLayoutEffect, useState } from 'react';
const ProgressBar = (props) =>{
    const {startTimeMS, endTimeMS, id} = props;
    const [progressTimer, setProgressTimer] = useState(0);

    useEffect(() => {

      if(progressTimer && progressTimer < 100){
        const interval = setInterval(() => {
            setProgressTimer(Math.floor((Date.now()-startTimeMS)/(endTimeMS - startTimeMS)*100));
        }, 1000);

        return () => clearInterval(interval);
    }
    }, [progressTimer]);

    

    useLayoutEffect(()=>{
        if(startTimeMS > Date.now()){
            setProgressTimer(0);
        }else if(endTimeMS < Date.now()){
            setProgressTimer(100);
        }else{
            setProgressTimer(Math.floor((Date.now()-startTimeMS)/(endTimeMS - startTimeMS)*100));
        }

    },[startTimeMS, endTimeMS])
    return(
        <div className="progress sm-progress">
            <div className="progress-bar sm-progressbar" role="progressbar" style={{width: progressTimer+'%'}} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    )
}
    
export default ProgressBar;