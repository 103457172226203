import { useTranslation } from "react-i18next";
const StartButton = (props) =>{
    const {numGamesLeft,startTimeMS , endTimeMS,finished,clientUrl,userToken, cpuLevelUp} = props;
    const { t } = useTranslation();

    const openLink =(link) =>{
        window.open(link, "_self");
    }
  
    const lobbyURL = window.location.href.slice(0,window.location.href.indexOf("/lobby") );
    if (cpuLevelUp === true) return (<div className="align-items-end card-completedButton-sm fs-6 fw-bold"><span className="centreMessage">{t("BUSY")}</span></div>)
    if(finished) return (<></>) ;
    if (numGamesLeft<=0) return (<>{t("ALL_BATTLES_USED")}</>) ;
    if (startTimeMS && startTimeMS>Date.now()) return (<></>) ;
    if (endTimeMS && endTimeMS<Date.now()) return (<></>) ;
    // return (<a className="card-playButton" href={clientUrl.replace("[LOBBYURL]",lobbyURL+'?token='+userToken)}>
    // <div>{t("PLAY")}</div></a>) ;
    return (<div className="card-playButton" onClick={() => openLink(clientUrl.replace("[LOBBYURL]",lobbyURL+'?token='+userToken))}>
    <div>{t("PLAY")}</div></div>) ;
}

export default StartButton;