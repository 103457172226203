import React,{ useContext, useState } from "react";
import  UserContext from "../components/appContext";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Register = () => {
    const { onRegister,errorMessage } = useContext(UserContext);

    const [userActualName, setUserActualName] = useState('');
    const [userScreenName, setUserScreenName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userConfirmPassword, setUserConfirmPassword] = useState('');
    
    const submitLogin = () =>{
      onRegister(userActualName,userScreenName,userEmail,userPassword,userConfirmPassword,"")
    }
    return(

    <div className="register">
        <div className="form">
            <div className="align-items-baseline justify-content-end">
                <img  className="logo" alt="logo" src={require('../../assets/images/logo.png')} />
            </div> 
            <div className="pb-1">WELCOME TO SLOT MASTERS</div>
            <div className="pb-2">THE WORLDS FIRST, 3 WAY HEAD TO HEAD SLOT GAME</div>
            <input className="mb-1" type="text" name="actualname" id="actualname" required="true" maxLength="24" value={userActualName} placeholder="ENTER YOUR NAME" onChange={e => setUserActualName(e.target.value)}></input><br/>
            <input className="mb-1" type="text" name="name" id="name" required="true" maxLength="10" value={userScreenName} placeholder="ENTER A SCREEN NAME" onChange={e => setUserScreenName(e.target.value)}></input><br/>
            <input className="mb-1" type="text" name="email" id="email" required="true" value={userEmail} placeholder="ENTER YOUR EMAIL" onChange={e => setUserEmail(e.target.value)}></input><br/>
            <input className="mb-1" type="password" name="password" id="password" required="true" minLength="8" maxLength="24" value={userPassword} placeholder="ENTER YOUR PASSWORD" onChange={e => setUserPassword(e.target.value)}></input><br/>
            <input className="mb-1" type="password" name="confirmpassword" id="confirmpassword" required="true" minLength="8" maxLength="24" value={userConfirmPassword} placeholder="CONFIRM PASSWORD" onChange={e => setUserConfirmPassword(e.target.value)}></input><br/>
            <div className="error-message">{errorMessage}</div>
            <input type="button" className="mb-2 mt-2" value="Login" onClick={submitLogin}/><br/>
            <div><Link className="text-link" to="/">Already registered?</Link></div>
            <div className="">Email and name details are used solely to register and login to this site. Please refer to our Privacy Policy for further details.</div>
        </div>
    </div>
        
    );
};
  
export default Register;