import React,{ useContext, useState } from "react";
import  UserContext from "../components/appContext";
import {Navigate, useLocation} from 'react-router-dom';
import { Link } from "react-router-dom";

const Login = () => {
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const { showLogin, onLogin,errorMessage,userToken, urlToken } = useContext(UserContext);
    const location = useLocation();
    

    const submitLogin =() =>{
      onLogin(userEmail,userPassword); 
    }

    if (userToken) {
        return <Navigate to={`/${urlToken}`} replace state={{ from: location }}/>;
    }

    return(
      <>
      {showLogin && 
        <div className="register">
            <div className="form">
                <div className="align-items-baseline justify-content-end">
                    <img  className="logo" alt="logo" src={require('../../assets/images/logo.png')} />
                </div> 
                <div className="pb-1">WELCOME TO SLOT MASTERS</div>
                <div className="pb-2">THE WORLDS FIRST, 3 WAY HEAD TO HEAD SLOT GAME</div>
                <input className="mb-1" type="text" id="email" value={userEmail} placeholder="ENTER YOUR EMAIL" onChange={e => setUserEmail(e.target.value)}></input><br/>
                <input className="mb-1" type="password" id="password" minLength="8" maxLength="24" value={userPassword} placeholder="ENTER YOUR PASSWORD" onChange={e => setUserPassword(e.target.value)}></input><br/>
                <div className="error-message">{errorMessage}</div>
                <input type="button" className="mb-2 mt-2" value="Login" onClick={submitLogin}/><br/>
                <div><Link className="text-link" to="/Register">Not registered?</Link></div>
            </div>
        </div>
      }
      </>
    );
};
  
export default Login;