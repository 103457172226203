import { useParams } from "react-router-dom";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import  UserContext from "../components/appContext";
import { useTranslation } from "react-i18next";
import axios from "axios";

const MyBattles = () => {
const { userId, userToken,urlToken, databaseURL } = useContext(UserContext);
  const paraData = useParams();
  const [id, setId] = useState(paraData.tournamentid);
  const [tournamentData, setTournamentData] = useState();
  const [tournamentName, setTournamentName] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [timeZone, setTimeZone] = useState(-(new Date().getTimezoneOffset()*60*1000)); //*60*1000


    const getMyBattles = async () =>{
        setLoading(true);
        return await axios
            .get(`${databaseURL}/api/lobby/mytournaments/games/${userId}/${id}`)
            .then((response) => {
                if(response && response.data && response.data.myBattles){
                    setTournamentData(response.data.myBattles);
                    setTournamentName(response.data.myBattles[0].name);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }
    useEffect (() =>{
        getMyBattles();
      

    },[])


    return (
      <div className="container-page">
            <div className="content-page">
                <div className="row gx-1 mb-1">
                    <div className="col-9">
                      <h5>{tournamentName} ({id})</h5>
                    </div>
                    <div className="col-3">
                        <div className="text-end"><Link className="text-link" to={`/mytournaments${urlToken}`}>{t("BACK")}</Link></div>
                    </div>
                </div>
                <div className="card-table">
                    <div className="row gx-1">
                        <div className="col-2 table-col">
                            <div className="p-1 title">{t("ID")}</div>
                        </div>
                        <div className="col-4 table-col">
                            <div className="p-1 title">{t("WINNER")}</div>
                        </div>
                        <div className="col-4 table-col">
                            <div className="p-1 title">{t("DATE")}</div>
                        </div>
                        <div className="col-2 table-col">
                            <div className="p-1 title"></div>
                        </div>
                    </div>
               
                    {tournamentData && tournamentData.map((data,index) => (
                        <div key={index} className="row gx-1 table-row ">
                            <div className="col-2 table-col">
                                <div className="p-1">{data.id}</div>
                            </div>
                            <div className="col-4 table-col">
                                {data.sitAndSpinPrize ? 
                                <div className="p-1">{data.winnerName} ({data.sitAndSpinPrize})</div>
                                :
                                <div className="p-1">{data.winnerName} ({data.totalWinnings})</div>
                                }
                            </div>
                            <div className="col-4 table-col">
                                <div className="p-1">{new Intl.DateTimeFormat("en-GB", { 
                                            year: "numeric",
                                            month: "numeric",
                                            day: "2-digit",
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: false,
                                        }).format(new Date(data.startTimeDate.replace(/ /g,"T")).getTime()+timeZone)}</div>
                            </div>
                            <div className="col-2 table-col">
                                <div className="p-1"><Link to={`/gamedetails/${data.id}/${id}${urlToken}`} className="page-button">{t("VIEW")}</Link></div>
                            </div>
                        </div>
                        ))
                    }
                    </div>
            </div>
      </div>
    );
    
  };
  
  export default MyBattles;