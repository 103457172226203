import React,{ useEffect, useState,useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import  UserContext from "../components/appContext";
import { useTranslation } from "react-i18next";
import axios from "axios";

const MyTournaments = () => {
    let [myTournaments, setMyTournaments] = useState();
    const { userId, userToken,urlToken, databaseURL } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const fetchMyTournaments = async () =>{
        setLoading(true);
        return await axios
            .get(`${databaseURL}/api/lobby/mytournaments/${userId}`)
            .then((response) => {
                if(response && response.data && response.data.myTournaments){
                    setMyTournaments(response.data.myTournaments); 
                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() =>{
        fetchMyTournaments();
    },[])

    return (
        <div className="container-page">
            <div className="content-page">
                <div className="row gx-1 mb-1">
                    <div className="col-8">
                        <h5 className = "mytournamentsHeader">{t("MY_TOURNAMENTS") }</h5>
                    </div>
                    <div className="col-4 text-end">
                        <Link to={'/archiveMyTournaments'+urlToken} className="text-link">{t("ARCHIVE")}</Link>
                    </div>
                </div>
                <div className="card-table">
                    <div className="row gx-1 ">
                        <div className="col-3 table-col">
                            <div className="p-1 title text-start">{t("NAME") }</div>
                        </div>
                        <div className="col-3 table-col">
                            <div className="p-1 title">{t("POSITION")}</div>
                        </div>
                        <div className="col-3 table-col">
                            <div className="p-1 title">{t("PRIZE")}</div>
                        </div>
                        <div className="col-3 table-col">
                            <div className="p-1 title">{t("BATTLES")}</div>
                        </div>
                    </div>

                    {myTournaments && myTournaments.map((data,index) => (
                        <div key={index} className="row gx-1 table-row ">
                            <div className="col-3 table-col">
                                <div className="p-1 text-start">{data.name}</div>
                            </div>
                            <div className="col-3 table-col">
                                {data.mode && data.mode === 3 ?
                                    <div className="p-1">Sit & Spin</div>
                                    :
                                    <div className="p-1">{data.yourPosition === 0 ? "-" : data.yourPosition}</div>
                                    }
                            </div>
                            <div className="col-3 table-col">
                                <div className="p-1">{data.prize}</div>
                            </div>
                            <div className="col-3 table-col">
                                <div className="p-1">
                                    <Link to={'/mybattles/'+data.id+urlToken} className="page-button">{t("VIEW")}</Link>
                                </div>
                            </div>
                        </div>
                        ))
                    }
                    </div>
            </div>
    </div>
    );
};
  
export default MyTournaments;