import { useTranslation } from "react-i18next";
import CountdownTimer from './Timer/CountdownTimer';

const StartEndsInBlock = (props) =>{
    const {startTimeMS, endTimeMS,startTime,endTime,finished, timerFinishedHandler} = props;

    const { t } = useTranslation();
    if(finished){
        return (<><div className="card-heading">{t("TOURNAMENT")}</div>   
            <div className="card-detail">{t("FINISHED")}</div></>);
    }
    if(endTimeMS && endTimeMS<Date.now()){ 
        return (<><div className="card-heading">{t("TOURNAMENT")}</div>   
            <div className="card-detail">{t("FINISHED")}</div></>);
    }else if(endTimeMS && startTimeMS<Date.now() && endTimeMS>Date.now()){
        return(
            <><div className="card-heading">{t("ENDS")}</div> <CountdownTimer targetDate={endTimeMS} completeCallback={timerFinishedHandler}/></>
        )

    }else if(startTimeMS  && startTimeMS>Date.now()){
        return(
            <><div className="card-heading">{t("STARTS")}</div> <CountdownTimer targetDate={startTimeMS} completeCallback={timerFinishedHandler}/></>
        )
    }else{
        return (<><div className="card-heading"></div>   
            <div className="card-detail"></div></>)
    }
}

export default StartEndsInBlock;