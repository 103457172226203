import { Outlet, Link, NavLink } from "react-router-dom"
import React,{ useContext, useState } from "react";
import  UserContext from "../components/appContext";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Layout = () =>{
    const { userId, userName,urlToken, databaseURL, onLogout,userToken,showPopup,ctaBodyText,ctaBtnText,ctaBtnLink,setShowPopup, balance,playMode,setBalance } = useContext(UserContext);
    const { i18n,t } = useTranslation();

    const onUpdateBalance = async () =>{
        return await axios
            .get(`${databaseURL}/api/addFound/${userId}/1000`)
            .then((response) => {
                if(response && response.data.message === "ok"){
                    var newBalance = balance + 1000;
                    setBalance(newBalance); 
                }
                
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const termsOnClick = (link) =>{
        console.log("redirect")
        window.top.postMessage('SLOT_MASTERS_TERMS' , "*")
        window.parent.postMessage('SLOT_MASTERS_TERMS' , "*")  

    }

    const changeLanguage = () =>{
        i18n.changeLanguage("el");
    }

    return (
        
        <>
            <div className="topbar-content d-flex">
                <div className="left-column">
                    <img className="m-1 sm-logo" alt="logo" src={require('../../assets/images/logo.png')}/>   
              
                </div>
                <div className="center-column">
                    <div className="ps-2 pe-2">
                        <nav className="navbar navbar-expand pt-0">
                            <div className="d-flex nav-content">
                                <div className="justify-content-center navbar-collapse">
                                    
                                    <div className="mb-2 navbar-bg p-3 pt-1 pb-1">
                                        <div className="portrait-section">
                                            <div className="col-6">
                                                <img className="sm-logo-small" alt="logo" src={require('../../assets/images/logo.png')}/>   
              
                                            </div>
                                            <div className="col-6">
                                                <div className="sm-username">{userName}</div>
                                                {playMode === 'real' &&  
                                                    <div className="text-end pe-1" style={{color:"#ffffff", fontWeight:700,fontSize:"0.9rem"}}>Balance: £{(Math.round(balance) / 100).toFixed(2)}</div>
                                                }
                                            </div>
                                        </div>
                                        <ul className="navbar-nav justify-content-center">
                                            
                                            <li className="nav-item">
                                                <NavLink to={`/${urlToken}`} className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}>{t("LOBBY")}</NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} to={`/mytournaments${urlToken}`}>{t("MY_GAMES")}</NavLink>
                                            </li>
                                           
                                            <li className="nav-item">
                                                {/*<NavLink className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} to="/terms">{t("TERMS")}</NavLink>*/}
                                                <div className="nav-link" style={{cursor:"pointer"}} onClick={() => termsOnClick()}>{t("TERMS")}</div>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")} to={`/help${urlToken}`}>{t("HELP")}</NavLink>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </nav>
                        <Outlet />
                        
                        
                    </div>
                </div>
                <div className="right-column">
                    <div className="m-1 sm-username">{userName}</div>
                    {playMode === 'real' &&  
                        <div className="text-end pe-1" style={{color:"#ffffff", fontWeight:700,fontSize:"1rem"}} onClick={onUpdateBalance}>Balance: £{(Math.round(balance) / 100).toFixed(2)}</div>
                    }
                </div>
            </div>
            
            
           
        </>
    )
};

export default Layout;
