import { useState } from 'react';
import React from 'react'
//import Carousel from 'react-grid-carousel'
import { useSpringCarousel } from 'react-spring-carousel'
import { useTranslation } from "react-i18next";
import themes from "../../themes.json";


const WeaponBanner = (props) => {
    const {data, clientName, isOpen} = props;
    const [show, setShow] = useState(isOpen===true ? 'show' : 'hide');
    const [arrowBtn, setArrowBtn] = useState('');
    const [weapondesc,setWeapondesc] = useState("");
    const { t } = useTranslation();
 
    const { carouselFragment, slideToPrevItem, slideToNextItem } = useSpringCarousel({
        itemsPerSlide: data.length > 5 ? 6 : data.length ,
        disableGestures: true,
        items: data && data.map((weapon,index) => ({
          id: index,
          renderItem: (
            /*<BattleItem name={weapon.name} clientName={clientName} totalUses={weapon.totalUses} lifeTime={weapon.lifeTime} clickHandler={()=>showDescription(t(`${weapon.name.toUpperCase()}_DESC`), weapon.lifeTime)} />*/
            <BattleItem name={weapon.name} clientName={clientName} totalUses={weapon.totalUses} lifeTime={weapon.lifeTime} desc={`${weapon.name.toUpperCase()}_DESC`} clickHandler={(val,lifeTime)=>showDescription(val,lifeTime)} />
            
            
          ),
        })),
      })
      

    const expandSection = () => {
        setWeapondesc('');
        if(show === 'hide'){
            setShow('show');
            setArrowBtn('down');
        }else{
            setShow('hide');
            setArrowBtn('');
        }
    }
    const showDescription = (val, lifetime)=>{
        let desc = val;
        if(desc.indexOf('[X]') > -1){
            desc = desc.replace('[X]', lifetime);
        }
        setWeapondesc(`${desc}`)
    }

    return( 
        <div id="gameweaponsbanner" className="card-section-expand">
            <div className="card-banner" onClick={expandSection}>
                <div className="card-row">
                    <div className="col-4">
                        <div className="card-title-sm">{t("WEAPONS")}</div>
                    </div>
                    <div className="col-7 pt-2">
                        <div className="weapon-title">{weapondesc}</div>
                    </div>
                    <div className="col-1 d-flex align-items-center justify-content-end">
                        <img className={`card-arrow ${arrowBtn}`} alt="arrowSVG" src={require('../../assets/images/arrow.svg').default}/>   
                    </div>
                </div>
            </div> 
            
            <div id="gameweapons" className={`container card-weapons ${show}`} >
                <div className="carousel-container" style={{width:data.length > 4 ? "100%" : (data.length*16)+"%"}}>
                    <div className="carousel-holder">{carouselFragment}</div>
                    {data.length > 5 &&
                    <div className="left-arrow arrow-top" style={{paddingTop:"5%"}} onClick={slideToPrevItem}>
                        <img className={`card-arrow left`} alt="arrowSVG" src={require('../../assets/images/arrow.svg').default}/>   
                    </div>
                    }
                    
                    {data.length > 5 &&
                    <div className="right-arrow arrow-top" style={{paddingTop:"5%"}} onClick={slideToNextItem}>
                        <img className={`card-arrow right`} alt="arrowSVG" src={require('../../assets/images/arrow.svg').default}/>   
                    </div>
                    }
                </div>
                
                
            </div>
            
        </div>
    );
};
    
export default WeaponBanner;
const BattleItem = ({name,totalUses,lifeTime, clickHandler,clientName,desc}) =>{
    const { t } = useTranslation();
    let imgPath = "";
    let imgName = "";
    let imgDesc = "";
    let showUsage = true;
    let usagePos = "top";

    if(themes && themes.games){
        let result  = themes.games.filter((e)=> e.name === clientName );

        if(result && result[0]){

            let item = result[0].weapons.filter((e)=> e.name === name.toString().toLowerCase());
            if(item && item[0]){
                if(item[0].path){
                    imgPath = process.env.PUBLIC_URL +`/assets/${item[0].path}`;
                    imgName = item[0].altname;
                    if(item[0].description){
                        imgDesc = item[0].description;
                    }
                    console.log("item[0].showUsage=="+item[0].showUsage)
                    if(item[0].showUsage === false){
                        showUsage = false;
                    }
                    if(item[0].usagePos){
                        usagePos = item[0].usagePos;
                    }
                }
            }
        }
    }
    if(imgPath === ""){
        imgPath = require(`../../assets/images/buttons/old/${name.toString().toLowerCase()}off.png`);
        
    }
    if(imgName === ""){
        imgName = name.toUpperCase();
    }
    if(imgDesc === ""){
        imgDesc = desc;
    }

    imgDesc = t(`${imgName}`) +": "+ t(`${imgDesc}`);

    return (
        <div onClick={()=>clickHandler(imgDesc,lifeTime)}>
            {/*<div className="card-weapon-title">{t(name.toUpperCase())}</div>*/}
            <div className="card-weapon-container">
                <img className="card-weapon-img" alt={`weapon${imgName}`} src={imgPath} />
                {/*<img className="card-weapon-img" alt={`weapon${name}`} src={require(`../../assets/images/buttons/old/${name.toString().toLowerCase()}off.png`)} />*/}
                {showUsage == true && totalUses.toString() === "0" ?
                    <div className={`card-weapon-usage card-weapon-usage-${usagePos}`}><img alt="inifinate" src={require('../../assets/images/infinitySymbol.svg').default} className="infinitySymbol"/>   
                    </div>
                :
                    <div className={`card-weapon-usage card-weapon-usage-${usagePos}`}>{totalUses}</div>
                }
            </div>
            
        </div>
    )
}   

const BattleItem1 = (props) =>{
    const {name,totalUses,lifeTime, clickHandler,clientName} = props;
    const { t } = useTranslation();
    let imgPath = "";
    let imgName = "";

    if(themes && themes.games){

        let result  = themes.games.filter((e)=> e.name === clientName );
        if(result && result[0]){

            let item = result[0].weapons.filter((e)=> e.name === name.toString().toLowerCase());
            if(item && item[0]){
                if(item[0].path){
                    imgPath = process.env.PUBLIC_URL +`/assets/${item[0].path}`;
                    imgName = item[0].altname
                }
            }
        }
        if(imgPath === ""){
            imgPath = require(`../../assets/images/buttons/old/${name.toString().toLowerCase()}off.png`);
            
        }
        if(imgName === ""){
            imgName = name;
        }

    }
    
    return (
        <div onClick={clickHandler}>
            {/*<div className="card-weapon-title">{t(name.toUpperCase())}</div>*/}
            <div className="card-weapon-container">
                <img className="card-weapon-img" alt={`weapon${imgName}`} src={imgPath} />
                {/*<img className="card-weapon-img" alt={`weapon${name}`} src={require(`../../assets/images/buttons/old/${name.toString().toLowerCase()}off.png`)} />*/}
                {totalUses.toString() === "0" ?
                    <div className="card-weapon-usage card-weapon-usage-top"><img alt="inifinate" src={require('../../assets/images/infinitySymbol.svg').default} className="infinitySymbol"/>   
                    </div>
                :
                    <div className="card-weapon-usage card-weapon-usage-top">{totalUses}</div>
                 }
            </div>
            
        </div>
    )
}   