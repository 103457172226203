import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

const resources = {
    en: {
      translation: {
        MY_GAMES: "My Battles",
      },
    },
    lads:{
        translation: {
            MY_GAMES: "MY BATTLES",
          },
    },
    gr: {
      translation: {
        MY_GAMES: "Tα Παιχνίδια μου",
      },
    },
  };
  i18next
    .use(initReactI18next)
    .use(HttpApi)
    .init({
      lng: "en", //el, en, fanzone
      fallbackLng: "en",
      interpolation: {
        escapeValue: false,
      },
    });
  export default i18next;