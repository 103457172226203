import React, { useState, useEffect } from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import {useCountdown} from './useCountdown';
import { useTranslation } from "react-i18next";

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <p>FINISHED</p>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds, t }) => {
  return (
    <div className="show-counter">
      <div className="countdown-link">
        <DateTimeDisplay value={days} type={t('DAYS')} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={hours} type={t('HRS')} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={t('MINS')} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={t('SECS')} isDanger={false} />
      </div>
    </div>
  );
};

const CountdownTimer = ({ targetDate, state, completeCallback }) => {
  const { t } = useTranslation();
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  // state = longDate, shortDate, countdown
  //const [completed, setCompleted] = useState(false);
  //if(!completed){
    if (days + hours + minutes + seconds <= 0) {
        
    
        //setCompleted(true);
        completeCallback();
        return <></>;
      
    }else if(state === 'longDate'){
      return(
        <div className="card-detail">{new Intl.DateTimeFormat("en-GB", {  
          year: "2-digit",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
      }).format(new Date(targetDate))}</div>
      )
    }else if(state === 'shortDate'){
      return(
        <div className="card-detail">{new Intl.DateTimeFormat("en-GB", {  
          weekday: "short",
          hour: "numeric",
          minute: "numeric",
          hour12: true
      }).format(new Date(targetDate))}</div>
      )
    } else {
      return (
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
          t={t}
        />
      );
    }
  //}
};

export default CountdownTimer;