import WeaponBanner from './WeaponsBanner';
import LeaderboardBanner from './LeaderboardBanner';
import AchievementBanner from './AchievementBanner';
import React,{ useEffect, useLayoutEffect, useState,useContext, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import UserContext from './appContext';

import ProgressBar from './ProgressBar';
import StartButton from './StartButton';
import StartEndsInBlock from './StartEndsInBlock';
import axios from "axios";

const TournamentCard = ({data,reloadHandler, openPopup}) => {
    const [minView, setMinView] = useState('');
    const [cardState, setCardState] = useState('');
    const [arrowBtn, setArrowBtn] = useState('down');
    const [battleID, setBattleID] = useState(data.id)
    const [timeZone, setTimeZone] = useState(-(new Date().getTimezoneOffset()*60*1000)); //*60*1000

    const [startTime, setStartTime] = useState(data.startTime.replace(/ /g,"T"));
    const [endTime, setEndTime] = useState(data.endTime.replace(/ /g,"T"));
    const [startTimeMS, setStartTimeMS] = useState(new Date(data.startTime.replace(/ /g,"T")).getTime()+timeZone);
    const [endTimeMS, setEndTimeMS] = useState(new Date(data.endTime.replace(/ /g,"T")).getTime()+timeZone);

    const [clientName, setClientName] = useState(data.clientName);
    const [numGamesLeft, setNumGamesLeft] = useState(data.numGamesLeft);
    const [progressTimer, setProgressTimer] = useState(0);
    const [clientUrl, setClientUrl] = useState(data.clientUrl ? data.clientUrl : "")
    const {userId,userToken, balance,setBalance,cpuLevelUp} = useContext(UserContext)
    const [finished, setFinished] = useState(data.finished === 1 ? true : false);
    const { t } = useTranslation();
    
    const [playMode, setPlayMode] = useState(data.buyincost && data.buyincost> 0  ? 'real' : 'free')
    const [buyInCost,setBuyInCost] = useState(data.buyincost ? data.buyincost : null);
    const [cashpot,setCashpot] = useState(data.cashpot ? data.cashpot : null);
    const [hasBoughtEntryYet,setHasBoughtEntryYet] = useState(data.hasBoughtEntryYet ? data.hasBoughtEntryYet : null);


    useLayoutEffect(()=>{
        if(startTimeMS > Date.now()){
            setCardState('upcoming');
        }else if(endTimeMS < Date.now()){
            setCardState('completed');
        }else{

            setCardState('');
            if(numGamesLeft === 0){
                if(data.journeyEndText !== ""){
                    openPopup(data.journeyEndText,data.ctaText ? data.ctaText : "",data.clickthroughUrl?data.clickthroughUrl:"");
                }
            }
        }
    },[])

    const onBuyIn = async() =>{

        const stake = Math.round(buyInCost / 100);
        const newBalance = Math.round(balance / 100);
        if(stake>newBalance){
           return;
        }else{
            return await axios
                .get(`${process.env.REACT_APP_DATABASE_URL}/api/buyIn/${userId}/${battleID}`)
                .then((response) => {
                    if(response && response.data.message === "ok"){
                        var newBalance = balance - 1000;
                        setBalance(newBalance); 
                        setHasBoughtEntryYet(true);
                        if(response.data.cashpot){
                            setCashpot(response.data.cashpot);
                        }
                    }
                    
                })
                .catch((error) => {
                    console.log(error);
            })
        }
    }

    const timerFinishedHandler = () =>{
        reloadHandler();
    }

    const showLifeTime = (time) =>{
        let mins = Math.floor(time / 60);

        let secs = time % 60 ;
        if (mins===0)
            return `${secs} ${t("secs")}` ;
        else if (secs===0)
            return `${mins} ${t("min")}` ;
        else
            return `${mins} ${t("min")} : ${secs} ${t("secs")}` ;
    }    
 
    return( 
        
        <div className={`tournament-card pb-3 ${minView} ${cardState}`}>
            <div className="">
                <div className="card-maintitle">{data.name}</div>
               
                <div className="card-body">
                    <div className="card-row">
                        <div className="col-4">
                        <img className="card-image" alt="logo" src={data.gameIcon} />
                        </div>   
                        <div className="col-8">
                            <div className="card-row pb-2">
                                <div className="col-6">
                                    <div className="card-heading">{t("DURATION")}</div>
                                    <div className="card-detail">{showLifeTime(data.lifeTime)}</div>
                                </div>
                                <div className="col-6">
                                    <div className="card-heading">{t("OPPONENTS")}</div>
                                    <div className="card-detail">{data.numPlayers-1}</div>
                                </div>
                            </div>
                            <div className="card-row pb-3">
                                <div className="col-6">
                            
                                    <StartEndsInBlock  startTimeMS={startTimeMS} endTimeMS={endTimeMS} startTime={startTime} endTime={endTime} finished={finished} timerFinishedHandler={()=>timerFinishedHandler()} />
                                </div>
                                <div className="col-6">
                                    <div className="card-heading">{t("BATTLES_LEFT")}</div>
                                    <div className="card-detail">{numGamesLeft < 0 ? '0' : numGamesLeft}/{data.numPlays}</div>
                                </div>
                                
                            </div>
                            <div className="card-row">
                                <div className="col-6">
                                    {playMode=== 'real' && !hasBoughtEntryYet ? (
                                        <div>£{(Math.round(buyInCost) / 100).toFixed(2)} Entry
                                            <div className="card-playButton" onClick={onBuyIn}><div>BUY IN</div></div>
                                        </div>
                                    ):(
                                        <StartButton numGamesLeft={numGamesLeft} startTimeMS={startTimeMS} endTimeMS={endTimeMS} finished={finished} clientUrl={data.clientUrl} userToken={userToken}  cpuLevelUp={cpuLevelUp}/>
                                    )}
                                </div>
                                <div className="col-6">
                                    {playMode=== 'real' && cashpot !== null &&
                                        <>
                                            <div className="card-heading">CASH POT</div>
                                            <div className="card-detail">£{(Math.round(cashpot) / 100).toFixed(2)}</div>
                                        </>
                                    }
                                </div>
                            </div>
                            
                        </div> 

                    </div>
                </div>
                
            </div>
            <ProgressBar startTimeMS={startTimeMS} endTimeMS={endTimeMS} id={data.name}/>
                
            {cardState !== 'completed' && data.weapons.length>0 &&
            <WeaponBanner data={data.weapons} clientName={clientName}/>
            }  

            <LeaderboardBanner data={data} cardState={cardState} playMode={playMode} />

            {/*
            <AchievementBanner/>
            */}
        </div>
    );
};
    
export default TournamentCard;
