
import ReactDOM from "react-dom";
const PopUpBox = ({ show, data,close }) => {

    const ctaOnClick = (link) =>{
        const eventObj = {
            "type":"SLOT_MASTERS_REDIRECT",
            "slotMastersRedirectURL" : link
        }
        window.top.postMessage(eventObj , "*")
        window.parent.postMessage(eventObj , "*")  
    }

    function decodeHTMLEntities(text) {
        if(text && text !== ""){
            var entities = [
                ['amp', '&'],
                ['apos', '\''],
                ['#x27', '\''],
                ['#x2F', '/'],
                ['#39', '\''],
                ['#47', '/'],
                ['lt', '<'],
                ['gt', '>'],
                ['nbsp', ' '],
                ['quot', '"']
            ];
        
            for (var i = 0, max = entities.length; i < max; ++i)
                text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);
        
            return text;
        }
    }

    return (
      <>
        <div className={`popupContainer ${show}`} id="popupContainer">
            <div className="contentContainer">
                <div className="close" onClick={()=>close()}>
                    <img alt="closeButton" src={require(`../../assets/images/closeicon.png`)} />              
                </div>
                <div className="content">
                    <div className="text" dangerouslySetInnerHTML={{__html:decodeHTMLEntities(data.ctaBodyText)}}/>
                    <div className="buttons">
                        {data.ctaBtnLink &&
                        <div className="ctabtn" onClick={() => ctaOnClick(data.ctaBtnLink)}>{data.ctaBtnText}</div>
                        }
                        <div className="ctabtn" onClick={()=>close()}>VIEW LEADERBOARD</div>
                    </div>
                </div>
            </div>
        </div>

      </>
    );
  };
  
export default PopUpBox;
