import React,{useLayoutEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

const LeaderboardBanner = (props) => {
    let [show, setShow] = useState('hide');
    let [arrowBtn, setArrowBtn] = useState('');
    let [awards, setAwards] = useState(props.data.awards ? props.data.awards : "");
    let [leaderboardData, setLeaderboardData] = useState(props.data.leaderboard ? props.data.leaderboard : []);
    
    let [playerData, setPlayerData] = useState([]); 
    const { t } = useTranslation();

    useLayoutEffect(() => {
        
        if(leaderboardData){
            
            getPlayerData(leaderboardData);
        }
    },[])

    const getPlayerData = (data) =>{
        const filtered = data.filter(obj => {
            return obj.highlight === 1;
        });

        if(filtered){
            setPlayerData(filtered[0]);
        }
    }

    const getAward = (rank,cssCol) =>{
        let award = "";


        if(awards){
            awards.forEach((item, i)=> {
                if(rank === item.position){
                    if(item.award.indexOf("FREESPINS")>-1){
                        let awardVal = item.award.slice(0, item.award.indexOf("FREESPINS"));
                        award = <>{awardVal} <img alt="icon" className={`entain-table-sm-icons ${cssCol}`} src={require('../../assets/images/slots-race-free-spins.svg').default} /></>;
                    }else if(item.award.indexOf("BONUS")>-1){
                        let awardVal = item.award.slice(0, item.award.indexOf("BONUS"));
                        award = <>{awardVal} <img alt="icon" className={`entain-table-sm-icons ${cssCol}`} src={require('../../assets/images/slots-race-bonus.svg').default} /></>;
                    }else if(item.award.indexOf("CASH")>-1){
                        let awardVal = item.award.slice(0, item.award.indexOf("CASH"));
                        award = <>{awardVal} <img alt="icon" className={`entain-table-sm-icons ${cssCol}`} src={require('../../assets/images/slots-race-cash.svg').default} /></>;
                    }else if(item.award.indexOf("FREEBETS")>-1){
                        let awardVal = item.award.slice(0, item.award.indexOf("FREEBETS"));
                        award = <>{awardVal} <img alt="icon" className={`entain-table-sm-icons ${cssCol}`} src={require('../../assets/images/freebet_Slot_masters.svg').default} /></>;
                    }else if(item.award.indexOf("£")>-1){
                        let awardVal = item.award.slice(item.award.indexOf("£")+1, item.award.length);
                        if(isNaN(awardVal)){
                            award = item.award;
                        }else{
                            awardVal = "£" + (Math.round(awardVal) / 100).toFixed(2);
                            award = <>{awardVal}</>;
                        }
                    }else{
                        award = item.award;
                    }
                }
            });
        }
        return award;
    }
    

    const expandSection = () => {
        if(show === 'hide'){
            setShow('show');
            setArrowBtn('down');
        }else{
            setShow('hide');
            setArrowBtn('');
        }
    }
    return( 
        <div className="card-section-expand">
                <div className={`card-banner ${props.cardState !== 'completed' ? 'white' : ''}`}  onClick={expandSection}>
                    <div className="card-row">
                        <div className="col-4">
                            <div className="card-title-sm">{t("LEADERBOARD")}</div>
                        </div>
                        <div className="col-7">
                            <div className="row card-desc">
                                <div className="col-3 ">
                                    <div className="card-desc-title">{t("RANK")}</div>
                                    <div className="">{playerData && playerData.rank}</div>
                                </div>
                                <div className="col-4">
                                    <div className="card-desc-title">{t("POINTS")}</div>
                                    <div className="">{playerData && playerData.numPoints}</div>
                                </div>
                                <div className="col-5">
                                    <div className="card-desc-title">{t("PRIZE")}</div>
                                    <div className="">{playerData && getAward(playerData.rank, "white-icon")}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 d-flex align-items-center justify-content-end">
                            <img className={`card-arrow ${arrowBtn}`} alt="arrowSVG" src={require('../../assets/images/arrow.svg').default}/>   
                        </div>
                    </div>
                </div>
                <div className={`container card-table ${show}`}>
                    <div className="row gx-1 mb-1">
                        <div className="col-2 table-col">
                            <div className="p-1 title">{t("RANK")}</div>
                        </div>
                        <div className="col-4 table-col">
                            <div className="p-1 title">{t("PLAYER")}</div>
                        </div>
                        <div className="col-3 table-col">
                            <div className="p-1 title">{t("POINTS")}</div>
                        </div>
                        <div className="col-3 table-col">
                            <div className="p-1 title">{t("PRIZE")}</div>
                        </div>
                    </div>
                    {leaderboardData && leaderboardData.map((player,index) => (
       
                        <div key={index} className={`row gx-1 mb-1 table-row ${player.highlight === 1 ? "highlight" : "" }`}>
                            <div className="col-2 table-col">
                                <div className="p-1">{player.rank}</div>
                            </div>
                            <div className="col-4 table-col">
                                <div className="p-1">{player.name}</div>
                            </div>
                            <div className="col-3 table-col">
                                <div className="p-1">{player.numPoints}</div>
                            </div>
                            <div className="col-3 table-col">
                                <div className="p-1">{getAward(player.rank,"")}</div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        );
};
    
export default LeaderboardBanner;