import React from "react";
const NoPage = () => {
    return (
      <div className="container-page">
            <div className="content-page">
                <h3>404 - page not found</h3>
            </div>
      </div>
    );
    
  };
  
  export default NoPage;