import { createContext } from 'react';

const UserContext = createContext({
    userToken:'',
    userId:'',
    userName:'',
    languageCode:'',
    teamName:'',
    template:'',
    companyName:'',
    operatorName:'',
    casinoLobbyUrl: '',
    databaseURL:'',
    lobbyRefreshTime:300000,
    tutorialVideo:'',
    faq:'',
    balance:null,
    authenticated:true,
    loggedIn:true,
    screenNameRequired:false,
    playMode:'free',
    cpuLevelUp:0,
    downForMaintenance:0
});

export default UserContext; 